import { render, staticRenderFns } from "./r2-reveal-slide.vue?vue&type=template&id=84e11e56&scoped=true&lang=pug"
import script from "./r2-reveal-slide.vue?vue&type=script&lang=coffee"
export * from "./r2-reveal-slide.vue?vue&type=script&lang=coffee"
import style0 from "./r2-reveal-slide.vue?vue&type=style&index=0&id=84e11e56&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84e11e56",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlocksMultiSlideMarqueeR2RevealSlidePoster: require('/opt/build/repo/site/components/blocks/multi-slide-marquee/r2-reveal-slide/poster.vue').default,BlocksMultiSlideMarqueeR2Rotation: require('/opt/build/repo/site/components/blocks/multi-slide-marquee/r2-reveal-slide/r2-rotation/r2-rotation.vue').default,BlocksMultiSlideMarqueeR2RevealSlideOMask: require('/opt/build/repo/site/components/blocks/multi-slide-marquee/r2-reveal-slide/o-mask/o-mask.vue').default})
