
export default

	props:
		headline: String
		subhead: String
		landscapeImage: Array | Object
		portraitImage: Array | Object
		landscapeVideo: Array | Object
		portraitVideo: Array | Object

	methods:

		# Scale the background image
		onImageLoaded: ->
			@$gsap.to @$refs.image.$el,
				scale: 1.1
				scrollTrigger:
					trigger: @$el
					start: 'top top'

