
import slideMixin from '../slide-mixin'
export default

	mixins: [ slideMixin ]

	data: ->
		scrollProgress: 0
		maskY: 0
		maskWidth: 0

	# Wait until the page is transitioned and for notification bar to open before
	# pinning elements because the fixed position is not tweened otherwise
	mounted: ->	@$waitForPageTransition @motionSetup
	destroyed: -> @pinningScrollTrigger?.kill()

	computed:

		# Make the clip path style
		clipPathStyle: ->
			radius = @maskWidth / 2
			position = "center calc(50% + #{@maskY}px)"
			'-webkit-clip-path': "circle(#{radius}px at #{position})"
			'clip-path': "circle(#{radius}px at #{position})"
			'--bkg-color': @slide.backgroundColor

		slideHeight: -> switch @slide.animationFrameRate
			when 'long' then "400vh"
			when 'extra-long' then "700vh"
			when 'xx-long' then '1000vh'
			else "250vh"

	methods:

		motionSetup: ->

			# Pin the pre- and post-o items in place
			@pinningScrollTrigger = @$ScrollTrigger.create
				trigger: @$el
				pin: @$refs.pinned
				# pinSpacing: false
				start: 'top top'
				end: @scrollEnd
				onUpdate: ({ progress }) => @scrollProgress = progress

		scrollEnd: -> return switch @slide.animationFrameRate
			when 'long' then "+=#{window.innerHeight * 3}"
			when 'xx-long' then "+=#{window.innerHeight * 9}"
			when 'extra-long' then "+=#{window.innerHeight * 6}"
			else "bottom bottom"

