###
Shared code of all slides, used to report their position
###

# Export settings config
export settings =
	revealStart: 'top 90%'
	revealEnd: 'top 60%'

# The mixin
export default

	props: slide: Object

	# Report on the progress of this slide into the viewport
	mounted: ->
		@backgroundRevealTrigger = @$ScrollTrigger.create
			trigger: @$el
			start: 'top 60%'
			end: 'top 50%'
			onUpdate: ({ progress }) =>
				@$emit 'background-reveal', {
					progress
					id: @slide.id
				}

	destroyed: -> @backgroundRevealTrigger?.kill()

	methods:

		# Fade in a typical slide header
		revealWhenInside: (el) ->
			@$gsap.timeline
				scrollTrigger:
					trigger: el
					start: settings.revealStart
					end: settings.revealEnd
			.fromTo el, { opacity: 0 }, { opacity: 1 }

		# Add scaling animation
		scaleDuringScroll: (el) ->
			@$gsap.timeline
				scrollTrigger:
					trigger: el
					start: settings.revealStart
			.to el, scale: 1.2
