
import pngMask from './o-mask.png'
import debounce from 'lodash/debounce'

holeRatio = 1920 / 630 # The ratio of the length of the hole in the "o"
export default

	data: ->
		pngPath: pngMask
		viewport:
			height: 0
			width: 0

	# Init
	mounted: ->
		@captureViewportSize()
		@motionSetup()
		window.addEventListener 'resize', @captureViewportSize

	# Cleanup
	destroyed: ->
		@motionCleanup()
		window.removeEventListener 'resize', @captureViewportSize

	computed:

		# Figure out the starting mask size
		startingSize: -> @viewport.width * 1.1

		# Figure out ending mask size
		endingSize: ->
			Math.max @viewport.width * holeRatio,
				@viewport.height * holeRatio

		# The starting offset is the height of the viewport plus the extra we need
		# to compensate for if the starting size is bigger than a viewport.
		startingY: ->
			@viewport.height +
			Math.max 0, (@startingSize - @viewport.height) / 2 + 1

	watch:

		# Update viewport size dependent tweens
		'viewport.height': (now, old) ->
			@refreshResponsiveTweens() if old
		'viewport.width': (now, old) ->
			@refreshResponsiveTweens() if old

	methods:

		# Store the viewport height
		captureViewportSize: ->
			@viewport.height = window.innerHeight
			@viewport.width = window.innerWidth

		# Rotate and scale the O as this component moves out of the viewport
		motionSetup: ->

			# Reveal this component
			@$el.style.display = 'block'

			# Make the timeline
			@timeline = @$gsap.timeline scrollTrigger:
				trigger: @$el
				end: 'top 20%' # Speed up with relation to the overall animation

				# Emitting these from scrollTrigger callback rather than tweens because
				# the progress was wonky when emitted from tweens when resizing
				# browser
				onUpdate: =>
					@$emit 'y', @$gsap.getProperty @$refs.translator, 'y'
					@$emit 'width', @$gsap.getProperty @$refs.mask, 'width'

			# Add tweens that get regenerated on resize because they measure the
			# window
			@addTranslateTween()
			@addScaleTween()

			# Be slow to start rotating. Reading the transform
			@timeline.fromTo @$refs.mask,
				rotate: '45deg'
			,
				duration: 1
				ease: 'power1.in'
				rotate: '90deg'
			, 0

			# In the last 10%, fade out completely
			@timeline.set @$refs.mask, { display: 'none' }, 1

		# Move into frame quickly
		addTranslateTween: ->

			# Cleanup old instance
			@translateTween?.kill()

			# Make the tween
			@translateTween = @$gsap.fromTo @$refs.translator,
				y: @startingY
			,
				duration: 0.7
				ease: 'power2.out'
				y: 0

			# Add it to the timeline
			@timeline.add @translateTween, 0

		# Be even slower to start scaling. Not using actual scale so it doesn't
		# blur in safari. This gets regenerated on resize
		addScaleTween: ->

			# Cleanup old instance
			@scaleTween?.kill()

			# Make the tween
			@scaleTween = @$gsap.fromTo @$refs.mask,
				width: @startingSize
			,
				duration: 0.9
				delay: 0.1 # Start late
				ease: 'power2.in'
				width: @endingSize

			# Add it to the timeline
			@timeline.add @scaleTween, 0

		# Refresh responsive tweens
		refreshResponsiveTweens: debounce ->
			@addTranslateTween()
			@addScaleTween()
			@$ScrollTrigger.refresh()
		, 1000

		# Cleanup gsap
		motionCleanup: ->
			@timeline?.scrollTrigger?.kill() # Cleanup onUpdate
			@timeline?.kill() # Just in case

