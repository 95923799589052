import { render, staticRenderFns } from "./poster.vue?vue&type=template&id=4b315c3c&scoped=true&lang=pug"
import script from "./poster.vue?vue&type=script&lang=coffee"
export * from "./poster.vue?vue&type=script&lang=coffee"
import style0 from "./poster.vue?vue&type=style&index=0&id=4b315c3c&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b315c3c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveCraftVisual: require('/opt/build/repo/site/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default,MarqueeHeadline: require('/opt/build/repo/site/components/globals/marquee-headline.vue').default})
