
import debounce from 'lodash/debounce'


# Settings
# frameLoadIncrement = 1 # Increase to decrease total load but also FPS
# startTrigger = 0.08 # The scroll progress where video starts rotating
endTrigger = 1 # The progress when rotating should stop
nativeWidth = 1920
nativeHeight = 1080


# The plugin
export default

	props:
		scrollProgress: Number
		copy: String
		title: String
		subtitle: String
		buttonText: String
		buttonURL: String
		backgroundColor: String
		uniqueFrames: Number
		lastFrameHangCount: Number
		textShow: Number
		textHide: Number
		ctaShow: Number
		ctaHide: Number
		filePath: String
		filePathPortrait: String
		fileExtension: String
		revealSlideSubheadHighlight: Array
		highlightColor:
			type: String
			default: 'grey-warm-02'
		startTrigger:
			type: Number
			default: 0.08
		frameLoadIncrement:
			type: Number
			default: 1


	data: ->
		width: null
		height: null
		frames: []
		loadedFrames: []
		framesLoaded: 0


	# Measure the window, which kicks off loading
	mounted: ->
		@setDimensions()
		window.addEventListener 'resize', @onResize

	destroyed: -> window.removeEventListener 'resize', @onResize

	computed:
		currentHighlightedText: ->
			return if !@hasHighlightedSubtext
			@revealSlideSubheadHighlight[@currentHighlightedTextIndex]?.text

		currentHighlightedTextIndex: ->
			return if !@hasHighlightedSubtext
			for entry,i in @highlitedTextLocations
				return i if entry[0] <= @scrollProgress < entry[1]

		hasHighlightedSubtext: -> !!@revealSlideSubheadHighlight && !!@revealSlideSubheadHighlight.length

		highlitedTextLocations: ->
			return unless @hasHighlightedSubtext
			return @revealSlideSubheadHighlight.map (entry, idx, arr) =>
				if idx == arr.length - 1 then return [entry.timelinePosition, 100]
				return [entry.timelinePosition, arr[idx+1].timelinePosition]

		lastFrame: -> @uniqueFrames + @lastFrameHangCount

		# The progress of the scrub through the video
		scrubProgress: ->
			perc = (@scrollProgress - @startTrigger) / (endTrigger - @startTrigger)
			Math.max 0, perc

		# The closest frame to the scrub progress
		currentFrame: -> Math.round @scrubProgress * (@frames.length - 1)

		# When to reveal the text
		revealText: -> @textHide > @scrollProgress > @textShow

		# When to reveal the button
		revealButton: -> @ctaHide > @scrollProgress > @ctaShow

		# Frame load percentage
		loadProgress: ->
			return 0 unless @frames.length
			@framesLoaded / @frames.length

		# All frames loaded
		loadComplete: -> @loadProgress == 1

		# Get the area of the canvas, for triggering watcher
		canvasArea: -> @width * @height

		classes: -> [if @revealButton then 'above']

		computedBackgroundColor: -> @backgroundColor?.replace '#', ''

		bgString: ->
			return '' if @fileExtension != 'png'
			return "&bg=#{@computedBackgroundColor}" if @fileExtension == 'png'

		isLandscape: -> (@width/@height) > 1

		computedFilePath: -> if @isLandscape then @filePath else @filePathPortrait

	watch:

		# Render the closest frame to the current progress
		currentFrame: -> @drawCurrentFrame()

		# When all frames are loaded, draw the current frame. This a cheap way to
		# get the image to re-render during HMR.
		loadComplete: (complete) -> @drawCurrentFrame() if complete

		# When dimensions change re-init
		canvasArea: ->
			@initCanvas()
			@loadFrames()

	methods:

		# Use a long debounce since there are expensive side effects
		onResize: debounce (-> @setDimensions()), 1000

		# Set the width of the canvas/image to the closest interval value. Using
		# the offsetHeight of this element because it is unaffected by mobile
		# browser chrome.  This is similar to how vhCheck measures it's `vh` var.
		# This is important because we don't want to trigger a new frameset load
		# if the mobile chrome crosses the intervalRound threshold.
		setDimensions: ->
			width = @$refs.canvas.offsetWidth
			height = @$refs.canvas.offsetHeight

			@width = width
			@height = height

		# Round a value and snap to the nearest interval
		intervalRound: (value, interval = 200) ->
			interval * Math.ceil value / interval

		# Setup the canvas
		initCanvas: ->
			return unless @$refs.canvas
			@$refs.canvas.width = @width
			@$refs.canvas.height = @height
			@canvas = @$refs.canvas.getContext '2d'

		# Load all of the frames all at once and let browser sort it out
		loadFrames: ->
			@frames = []
			@framesLoaded = 0
			for index in [0..@lastFrame] by @frameLoadIncrement
				img = new Image
				img.onload = => @framesLoaded++
				img.src = @imgSrc index
				@frames.push img

		# Make the src URL at a given index
		# Images uploaded to cloud.digitalocean.com/spaces/nuro
		imgSrc: (index) ->
			num = @$padNum Math.min(index, @uniqueFrames-1), 5
			"https://nuro.imgix.net/#{@computedFilePath}-#{num}.#{@fileExtension}?\
				w=#{@width}&h=#{@height}#{@bgString}&fit=crop&ar=#{@width}:#{@height}&auto=format&auto=compress"

		# Draw the current frame to the canvas
		drawCurrentFrame: ->
			return unless @canvas
			@canvas.clearRect 0, 0, @width, @height
			@canvas.drawImage @frames[@currentFrame], 0, 0

